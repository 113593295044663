.navbar {
    background: linear-gradient(to right, #1a237e, #283593) !important;
    padding: 0.8rem 1rem;
  }
  
  .nav-link {
    color: rgba(255, 255, 255, 0.85) !important;
    transition: all 0.3s ease;
    position: relative;
    border-radius: 4px;
    margin: 0 0.2rem;
  }
  
  .nav-link:hover {
    color: #fff !important;
    background: rgba(255, 255, 255, 0.1);
  }
  
  .nav-link.active {
    color: #fff !important;
    background: rgba(255, 255, 255, 0.15);
  }
  
  .navbar-brand {
    font-size: 1.4rem;
  }
  
  .navbar-toggler:focus {
    box-shadow: none;
  }
  
  .btn-outline-light {
    transition: all 0.3s ease;
    border-width: 2px;
  }
  
  .btn-outline-light:hover {
    transform: translateY(-1px);
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  }
  
  .user-link {
    color: rgba(255, 255, 255, 0.85) !important;
    transition: all 0.3s ease;
    padding: 0.5rem 1rem;
    border-radius: 4px;
  }
  
  .user-link:hover,
  .user-link.active {
    color: #fff !important;
    background: rgba(255, 255, 255, 0.1);
  }
  
  @media (max-width: 768px) {
    .navbar-collapse {
      padding: 1rem 0;
    }
    
    .nav-link {
      padding: 0.8rem 1rem;
      margin: 0.2rem 0;
    }
  
    .user-link {
      padding: 0.8rem 1rem;
      margin: 0.2rem 0;
    }
  }
  
  /* Animación suave para los iconos */
  .bi {
    transition: transform 0.3s ease;
  }
  
  .nav-link:hover .bi,
  .user-link:hover .bi {
    transform: scale(1.1);
  }